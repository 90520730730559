@media (-webkit-device-pixel-ratio: 1.25) {
  :root {
    zoom: 0.8;
  }
}

@media (-webkit-device-pixel-ratio: 1.5) {
  :root {
    zoom: 0.65;
  }
}

@media (-webkit-device-pixel-ratio: 1.75) {
  :root {
    zoom: 0.6;
  }
}


* {
  box-sizing: border-box;
  margin:0;
  padding:0;
  font-family: 'Montserrat', sans-serif;
}

body{
  /* background-image: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%); */
  /* background: linear-gradient(-90deg,#c4c4c3,rgba(196,196,195,0)); */

  /* background: linear-gradient(90deg,#c9c9c9,rgba(200,200,240,0)); */
}

input {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 1.4px;
}

textarea {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 1.4px;
}


/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #951f76 #ECE5F6;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #ECE5F6;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #951f76;
    border-radius: 10px;
    border: 1px solid #951f76;
  }

::selection {
  background: #951f76;
  color: #fff;
}








.container {
  flex: 0 1 700px;
  margin: auto;
  padding: 10px;
}

.screen {
  position: relative;
  border-radius: 15px;
}

.screen:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  bottom: 0;
  border-radius: 15px;
  /*box-shadow: 0 20px 40px rgba(0, 0, 0, .4);*/
  z-index: -1;
}



.screen-header-left {
  margin-right: auto;
}

.screen-header-button {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 3px;
  border-radius: 8px;
  background: white;
}


.screen-header-right {
  display: flex;
}

.screen-header-ellipsis {
  width: 3px;
  height: 3px;
  margin-left: 2px;
  border-radius: 8px;
  background: #999;
}

.screen-body {
  display: flex;
}

.screen-body-item {
  flex: 1;
  padding: 50px;
}

.screen-body-item.left {
  display: flex;
  flex-direction: column;
}

.app-title {
  display: flex;
  flex-direction: column;
  position: relative;
  color: #ea1d6f;
  font-size: 26px;
}

.app-title:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 25px;
  height: 4px;
  background: #ea1d6f;
}

.app-contact {
  margin-top: auto;
  font-size: 8px;
  color: #888;
}

.app-form-group {
  position: relative;
  margin-bottom: 20px;
}

.app-form-group.message {
  margin-top: 40px;
}

.app-form-group.buttons {
  margin-bottom: 0;
  text-align: right;
}

.app-form-control {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #b282a1;
  color: #ddd;
  font-size: 14px;
  outline: none;
  transition: border-color .2s;
}

.app-form-control-textarea {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #b282a1;
  color: #ddd;
  font-size: 14px;
  outline: none;
  transition: border-color .2s;
}

.app-form-control-textarea::placeholder {
  color: #ddd;
}

.app-form-control-textarea:focus {
  border-bottom-color: #ddd;
}


.app-form-control::placeholder {
  color: #ddd;
}

.app-form-control:focus {
  border-bottom-color: #ddd;
}












.contact-form .app-form-control {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #b282a1;
  color: #797979;
  font-size: 14px;
  outline: none;
  transition: border-color .2s;
}

.contact-form .app-form-control-textarea {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #b282a1;
  color: #797979;
  font-size: 14px;
  outline: none;
  transition: border-color .2s;
}

.contact-form .app-form-control-textarea::placeholder {
  color: #797979;
}

.contact-form .app-form-control-textarea:focus {
  border-bottom-color: #797979;
}


.contact-form .app-form-control::placeholder {
  color: #797979;
}

.contact-form .app-form-control:focus {
  border-bottom-color: #797979;
}













.app-form-button {
  background: none;
  border: none;
  color: #ea1d6f;
  font-size: 14px;
  cursor: pointer;
  outline: none;
}

.app-form-button:hover {
  color: #b9134f;
}

.credits {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #ffa4bd;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: normal;
}

.credits-link {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

.dribbble {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}

@media screen and (max-width: 520px) {
  .screen-body {
    flex-direction: column;
  }

  .screen-body-item.left {
    margin-bottom: 30px;
  }

  .app-title {
    flex-direction: row;
  }

  .app-title span {
    margin-right: 12px;
  }

  .app-title:after {
    display: none;
  }


  .overflowHidden {
    overflow: hidden;
    margin: 0;
    touch-action: none;
    -ms-touch-action: none;
    /* position: fixed; we get rid of this line which resets the flow of the page */
    /* height: 100%; we change 100% to 100vh */ 
    height: 100vh;
  }  

}

@media screen and (max-width: 990px) {

  .container{
    flex: unset;  
    padding:0px;  
  }
  .screen-body {
    padding: 0px;
    padding-top:30px;
  }

  .screen-body-item {
    padding: 0;
  }

  .hidden{
    display:none !important;
  }
}
@media screen and (min-width: 991px) and (max-width:1279px){
  .hidden{
    display:none !important;
  }  
}

@keyframes cssload-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@-o-keyframes cssload-rotate {
  100% {
    -o-transform: rotate(360deg);
  }
}

@-ms-keyframes cssload-rotate {
  100% {
    -ms-transform: rotate(360deg);
  }
}

@-webkit-keyframes cssload-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes cssload-rotate {
  100% {
    -moz-transform: rotate(360deg);
  }
}

.formInfo{
  color:#fff;
  text-align: left;
  font-size: 13px;
  font-style: italic;
  margin-top:5px;
}
.contact-form .formInfo{
  color:#951f76;
  text-align: left;
  font-size: 13px;
  font-style: normal;
  margin-top:5px;
}

.formInfoSub{
  color:#951f76;
  text-align: left;
  font-size: 15px;
  font-style: italic;
  margin-top:5px;
  font-weight: bold;
}


.app-form-control-sub {
  position: relative;
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #b282a1;
  color: #343434;
  font-size: 14px;
  outline: none;
  transition: border-color .2s;
}

.app-form-control-textarea-sub {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #b282a1;
  color: #343434;
  font-size: 14px;
  outline: none;
  transition: border-color .2s;
  position: relative;
  height: 200px;
}

.app-form-control-textarea-sub::placeholder {
  color: #c8c8c8;
}

.app-form-control-textarea-sub:focus {
  border-bottom-color: #c8c8c8;
}


.app-form-control-sub::placeholder {
  color: #979797;
  font-weight: normal;
}

.app-form-control-sub:focus {
  border-bottom-color: #c8c8c8;
}
.label-form{
  position: absolute;
  font-size: 13px;
  top: -10px;
  color:#951f76; 
  opacity: 0;
  font-weight: bold;
  z-index:2;
}

.app-form-group-sub {
  position: relative;
  margin-bottom: 20px;
}

.app-form-group-textarea-sub{
  position: relative;
  margin-bottom: 20px;
}

.slide-up {
  opacity: 1;
  -webkit-animation: slide-up 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
          animation: slide-up 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
}

@-webkit-keyframes slide-up {
  0% {
    transform: translateY(20px);
    opacity: 0; 
  }
  100% {
    transform: translateY(0);
    opacity: 1; 
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(20px);
    opacity: 0; 
  }
  100% {
    transform: translateY(0);
    opacity: 1; 
  }
}


.slide-down {
  opacity: 1;
  -webkit-animation: slide-down 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
          animation: slide-down 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
}

@-webkit-keyframes slide-down {
  0% {
    transform: translateY(0);
    opacity: 1;     
  }
  100% {
    transform: translateY(20px);
    opacity: 0; 
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(0);
    opacity: 1;     
  }
  100% {
    transform: translateY(20px);
    opacity: 0; 
  }
}